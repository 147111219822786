var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title[_vm.type]},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"标签"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dictLabel',
          {
            rules: [
              { required: true, message: '请输入标签！', whitespace: true } ],
            initialValue: _vm.type == 'add' ? '' : _vm.keyValInfo.dictLabel,
          } ]),expression:"[\n          'dictLabel',\n          {\n            rules: [\n              { required: true, message: '请输入标签！', whitespace: true },\n            ],\n            initialValue: type == 'add' ? '' : keyValInfo.dictLabel,\n          },\n        ]"}],attrs:{"placeholder":"请输入","maxLength":45}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('dictLabel'))+"/45")])],1),_c('a-form-item',{attrs:{"label":"键值"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dictValue',
          {
            rules: [
              { required: true, message: '请输入键值', whitespace: true } ],
            initialValue: _vm.type == 'add' ? '' : _vm.keyValInfo.dictValue,
          } ]),expression:"[\n          'dictValue',\n          {\n            rules: [\n              { required: true, message: '请输入键值', whitespace: true },\n            ],\n            initialValue: type == 'add' ? '' : keyValInfo.dictValue,\n          },\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dictSort',
          {
            rules: [{ required: true, message: '请输入排序！' }],
            initialValue: _vm.type == 'add' ? '' : _vm.keyValInfo.dictSort,
          } ]),expression:"[\n          'dictSort',\n          {\n            rules: [{ required: true, message: '请输入排序！' }],\n            initialValue: type == 'add' ? '' : keyValInfo.dictSort,\n          },\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"是否默认"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'isDefault',
          { initialValue: _vm.type == 'add' ? 'N' : _vm.keyValInfo.isDefault } ]),expression:"[\n          'isDefault',\n          { initialValue: type == 'add' ? 'N' : keyValInfo.isDefault },\n        ]"}]},[_c('a-radio',{attrs:{"value":"Y"}},[_vm._v(" Yes ")]),_c('a-radio',{attrs:{"value":"N"}},[_vm._v(" No ")])],1)],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'status',
          {
            valuePropName: 'checked',
            initialValue:
              _vm.type == 'add'
                ? false
                : _vm.keyValInfo.status === '0'
                ? true
                : false,
          } ]),expression:"[\n          'status',\n          {\n            valuePropName: 'checked',\n            initialValue:\n              type == 'add'\n                ? false\n                : keyValInfo.status === '0'\n                ? true\n                : false,\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          { initialValue: _vm.type == 'add' ? '' : _vm.keyValInfo.remark } ]),expression:"[\n          'remark',\n          { initialValue: type == 'add' ? '' : keyValInfo.remark },\n        ]"}],attrs:{"placeholder":"请输入","maxLength":200,"rows":4}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('remark'))+"/200")])],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"btn-group"},[_c('a-button',{staticClass:"close-btn",on:{"click":_vm.handleCancel}},[_vm._v("关闭")]),(_vm.type == 'add')?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 添加 ")]):_vm._e(),(_vm.type == 'edit')?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 保存 ")]):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }