var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title[_vm.type],"width":560},on:{"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"是否公开"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'isPub',
          {
            valuePropName: 'checked',
            initialValue:
              _vm.type == 'add' ? false : _vm.dictInfo.isPub === '1' ? true : false,
          } ]),expression:"[\n          'isPub',\n          {\n            valuePropName: 'checked',\n            initialValue:\n              type == 'add' ? false : dictInfo.isPub === '1' ? true : false,\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dictName',
          {
            rules: [
              { required: true, message: '请输入描述', whitespace: true } ],
            initialValue: _vm.type == 'add' ? '' : _vm.dictInfo.dictName,
          } ]),expression:"[\n          'dictName',\n          {\n            rules: [\n              { required: true, message: '请输入描述', whitespace: true },\n            ],\n            initialValue: type == 'add' ? '' : dictInfo.dictName,\n          },\n        ]"}],attrs:{"maxLength":45,"placeholder":"请输入"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('dictName'))+"/45")])],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'dictType',
          {
            rules: [
              { required: true, message: '请输入类型', whitespace: true } ],
            initialValue: _vm.type == 'add' ? '' : _vm.dictInfo.dictType,
          } ]),expression:"[\n          'dictType',\n          {\n            rules: [\n              { required: true, message: '请输入类型', whitespace: true },\n            ],\n            initialValue: type == 'add' ? '' : dictInfo.dictType,\n          },\n        ]"}],attrs:{"placeholder":"请输入","disabled":_vm.type !== 'add'}})],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'status',
          {
            valuePropName: 'checked',
            initialValue:
              _vm.type == 'add' ? false : _vm.dictInfo.status === '0' ? true : false,
          } ]),expression:"[\n          'status',\n          {\n            valuePropName: 'checked',\n            initialValue:\n              type == 'add' ? false : dictInfo.status === '0' ? true : false,\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          { initialValue: _vm.type == 'add' ? '' : _vm.dictInfo.remark } ]),expression:"[\n          'remark',\n          { initialValue: type == 'add' ? '' : dictInfo.remark },\n        ]"}],attrs:{"placeholder":"请输入","maxLength":200,"rows":4}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('remark'))+"/200")])],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"btn-group"},[_c('a-button',{staticClass:"close-btn",on:{"click":_vm.handleCancel}},[_vm._v("取消")]),(_vm.type == 'add')?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 添加 ")]):_vm._e(),(_vm.type == 'edit')?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 保存 ")]):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }