<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-05-16 11:33:56
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-05-28 09:51:10
 * @FilePath: \cz-operation-web\src\views\dataManagement\dataDictionary.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-row class="dataDictionary" :gutter="16">
    <a-col :span="12" class="box">
      <a-card style="border-radius: 8px; border: none">
        <!-- <div class="title_option">
          <a-input-search
            placeholder="输入关键字"
            enter-button
            @search="onSearch"
            :style="{ width: '300px' }"
          />
          <a-button @click="addDictionary">新增</a-button>
        </div> -->
        <a-row>
          <a-col :span="4">
            <div class="card_title">数据字典</div>
          </a-col>
          <a-col :span="20">
            <a-button
              type="primary"
              style="float: right; margin-left: 15px; width: 69px"
              @click="addDictionary"
              class="add-btn"
              icon="plus"
              >新增</a-button
            >
            <!-- v-model="queryData" -->
            <a-input-search
              placeholder="输入关键字"
              v-model="searchValue"
              @search="onSearch"
              :style="{ width: '178px', float: 'right' }"
            />
          </a-col>
        </a-row>
        <div>
          <a-table
            v-if="dictList.length > 0"
            rowKey="dictId"
            :rowClassName="setRowClassName"
            :columns="columns1"
            :dataSource="dictList"
            :loading="loading"
            :pagination="pagination1"
            @change="handleTableChange1"
            :scroll="{ x: 700 }"
          >
            <template slot="dictType" slot-scope="text, record">
              <ellipsis :length="15" tooltip>{{ text }}</ellipsis>
            </template>
            <template slot="remark" slot-scope="text, record">
              <ellipsis :length="10" tooltip>{{ text }}</ellipsis>
            </template>
            <template slot="status" slot-scope="text, record">
              <a-switch
                :checked="text === '0' ? true : false"
                checked-children="开"
                un-checked-children="关"
                @change="(e) => onDict_typeChange(record, e)"
              />
            </template>
            <template slot="operation" slot-scope="text, record">
              <div class="editable-row-operations">
                <!-- <a @click="() => openDictDetail(record, 'view')">查看</a>
                <a-divider type="vertical" />
                <a @click="() => openDictDetail(record, 'edit')">修改</a>
                <a-divider type="vertical" />
                <a-popconfirm
                  v-if="dictList.length"
                  title="确定删除该数据?"
                  @confirm="() => onDictDelete(record.dictId)"
                >
                  <a href="javascript:;">删除</a>
                </a-popconfirm>
                <a-divider type="vertical" />
                <a @click="() => openKey(record)">管理键值</a> -->
                <a @click="() => openKey(record)">管理键值</a>
                <a-divider type="vertical" />
                <a-dropdown>
                  <a
                    class="ant-dropdown-link"
                    @click="(e) => e.preventDefault()"
                  >
                    更多 <a-icon type="down" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item class="option_item edit">
                      <a @click="() => openDictDetail(record, 'edit')">编辑</a>
                    </a-menu-item>
                    <a-menu-item class="option_item delete">
                      <a-popconfirm
                        v-if="dictList.length"
                        title="确定删除该数据?"
                        @confirm="() => onDictDelete(record.dictId)"
                      >
                        <a href="javascript:;">删除</a>
                      </a-popconfirm>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </template>
          </a-table>

          <!-- 字典空状态 -->
          <div class="empty" v-else>
            <div>
              <img src="~@/assets/dictionary/empty.png" alt="" />
            </div>
            <div>暂无内容</div>
            <div>请点击“新增”按钮添加字典</div>
          </div>
        </div>
        <!-- <a-button @click="openKey">管理键值</a-button> -->
      </a-card>
    </a-col>
    <a-col :span="12" class="box">
      <a-card style="border-radius: 8px; border: none">
        <!-- <a-button >关闭键值</a-button> -->
        <!-- <div class="keyName">{{ currentDict.dictName }}</div>
        <div class="title_option">
          <a-button @click="openAddKey">添加键值</a-button>
          <a-icon type="close" @click="closeKey"></a-icon>
        </div> -->
        <a-row v-if="Object.keys(currentDict).length !== 0">
          <a-col :span="9" class="card_title"
            >管理键值({{ currentDict.dictName }})</a-col
          >
          <a-col :span="15">
            <a-button
              type="primary"
              @click="openAddKey"
              class="add-btn"
              style="float: right; width: 69px"
              icon="plus"
              >新增</a-button
            >
          </a-col>
        </a-row>
        <a-table
          v-if="
            keyValueList.length > 0 && Object.keys(currentDict).length !== 0
          "
          ref="table"
          rowKey="dictCode"
          :bordered="false"
          :dataSource="keyValueList"
          :columns="columns2"
          :pagination="false"
          @change="handleTableChange2"
        >
          <template slot="status" slot-scope="text, record">
            <a-switch
              :checked="text === '0' ? true : false"
              checked-children="开"
              un-checked-children="关"
              @change="(e) => onKeyVal_typeChange(record, e)"
            />
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="editable-row-operations">
              <!-- <a @click="() => openKeyValDetail(record, 'edit')">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm
                v-if="dictList.length"
                title="确定删除该数据?"
                @confirm="() => onDataDelete(record.dictCode)"
              >
                <a href="javascript:;">删除</a>
              </a-popconfirm> -->
              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  更多 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item class="option_item edit">
                    <a @click="() => openKeyValDetail(record, 'edit')">编辑</a>
                  </a-menu-item>
                  <a-menu-item class="option_item delete">
                    <a-popconfirm
                      v-if="dictList.length"
                      title="确定删除该数据?"
                      @confirm="() => onDataDelete(record.dictCode)"
                    >
                      <a href="javascript:;">删除</a>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </a-table>

        <!-- 键值对空状态 -->
        <div class="empty" v-else>
          <div>
            <img src="~@/assets/dictionary/empty.png" alt="" />
          </div>
          <div>暂无内容</div>
          <div v-if="Object.keys(currentDict).length !== 0">
            请点击“新增”按钮添加键值对
          </div>
          <div v-else>请先在左侧选择要管理的字典</div>
        </div>
      </a-card>
    </a-col>
    <dictModal
      :visible.sync="addDictionaryVisible"
      :type="openDict_type"
      :dictInfo="currentDict"
      v-if="addDictionaryVisible"
      @refresh="refresh"
    ></dictModal>
    <keyValueModal
      :visible.sync="addKeyVisible"
      :type="openKeyVal_type"
      :keyValInfo="currentKeyVal"
      :currentDict="currentDict"
      v-if="addKeyVisible"
      @refresh="refresh"
    ></keyValueModal>
  </a-row>
</template>

<script>
import dictModal from './modal/dictModal.vue'
import keyValueModal from './modal/keyValueModal.vue'

const columns1 = [
  { title: '描述', dataIndex: 'dictName', width: '180px', align: 'left' },
  {
    title: '类型',
    dataIndex: 'dictType',
    scopedSlots: { customRender: 'dictType' },
    align: 'left',
    width: '180px',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' },
    align: 'left',
    width: '180px',
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'left',
  },
  {
    title: '操作',
    width: '180px',
    dataIndex: 'operation',
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
    align: 'left',
  },
]

const columns2 = [
  { title: '标签', dataIndex: 'dictLabel', align: 'left' },
  { title: '键值', dataIndex: 'dictValue', align: 'left' },
  { title: '排序', dataIndex: 'dictSort', align: 'left' },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    // fixed: 'right',
    width: '150px',
    scopedSlots: { customRender: 'operation' },
    align: 'left',
  },
]

export default {
  name: 'DataDictionary',
  components: { dictModal, keyValueModal },
  data() {
    return {
      // span: 24,
      isShowKey: false,
      addDictionaryVisible: false,
      columns1,
      columns2,
      openDict_type: 'add',
      currentDict: {},
      loading: false,
      dictList: [],
      keyValueList: [],
      addKeyVisible: false,
      openKeyVal_type: 'add',
      currentKeyVal: {},
      pagination1: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 10,
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
        size: 'small',
      },
      pagination2: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
      },
      searchValue: '',
    }
  },
  computed: {
    span() {
      return this.isShowKey ? 12 : 24
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.getDictionaryList()
      if (Object.keys(this.currentDict).length !== 0) {
        this.openKey(this.currentDict)
      }
    },
    refresh() {
      this.addDictionaryVisible = false
      this.addKeyVisible = false
      this.init()
    },

    setRowClassName(row, index) {
      // 根据row.rowKey或者其他条件决定添加的class
      if (row.dictId === this.currentDict.dictId) {
        return 'selectedClass'
      } else {
        return ''
      }
    },
    /* 获取字典列表 */
    async getDictionaryList(params = {}) {
      try {
        let res = await this.$api.getDictionaryList(params)
        console.log('被输出值{ res }的输出结果是：', res)
        if (res.code === 0) {
          this.dictList = res.data.records
          this.pagination1.current = res.data.page
          this.pagination1.pageSize = Number(res.data.size)
          this.pagination1.total = Number(res.data.total)
        }
      } catch (err) {
        console.log('err', err)
      }
    },
    /* 获取键值列表 */
    async getDictKeyValueList(params) {
      try {
        let res = await this.$api.getDictDataList(params)
        if (res.code === 0) {
          this.keyValueList = res.data.records
          this.pagination2.current = res.data.page
          this.pagination2.pageSize = Number(res.data.size)
          this.pagination2.total = Number(res.data.total)
        }
      } catch (err) {
        console.log('err', err)
      }
    },
    openKey(record) {
      this.currentDict = record
      this.isShowKey = true
      console.log('被输出值{ record }的输出结果是：', record)
      let { dictType } = record
      let params = {
        dictType,
        page: 1,
        limit: 10,
      }
      this.getDictKeyValueList(params)
    },
    closeKey() {
      this.currentDict = {}
      this.isShowKey = false
    },
    onSearch(value) {
      console.log('被输出值{ value }的输出结果是：', value)
      this.searchValue = value
      this.getDictTypeLikelist()
    },

    getDictTypeLikelist() {
      this.$api.dictTypeLikelist({ name: this.searchValue }).then((res) => {
        if (res.code == 0) {
          this.dictList = res.data.records
          this.pagination1.current = res.data.page
          this.pagination1.pageSize = Number(res.data.size)
          this.pagination1.total = Number(res.data.total)
        }
      })
    },
    addDictionary() {
      this.openDictDetail(null, 'add')
    },
    openDictDetail(record, type) {
      this.addDictionaryVisible = true
      if (record) this.currentDict = record
      this.openDict_type = type
    },
    /* 删除字典 */
    async onDictDelete(id) {
      try {
        let res = await this.$api.deleteDict({ id })
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.init()
        }
      } catch (err) {
        console.log(err, '删除字典出错')
      }
    },
    /* 增加键值 */
    openAddKey() {
      this.openKeyValDetail(null, 'add')
    },
    /* 编辑键值 */
    openKeyValDetail(record, type) {
      this.addKeyVisible = true
      if (record) this.currentKeyVal = record
      this.openKeyVal_type = type
    },
    /* 删除键值 */
    async onDataDelete(dictCode) {
      try {
        let res = await this.$api.deleteDictKeyVal({ id: dictCode })
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.init()
        }
      } catch (err) {
        console.log(err, '删除字典-键值对出错')
      }
    },
    /* 字典状态改变(字典状态开关) */
    async onDict_typeChange(record, flag) {
      console.log('被输出值{ record.,flag }的输出结果是：', record, flag)
      record.status = flag === true ? '0' : '1'
      try {
        let res = await this.$api.modifyDict(record)
        if (res.code === 0) {
          this.$message.success('修改成功')
          this.refresh()
        }
      } catch (err) {
        console.log(err, '字典开关错误')
      }
    },
    /* 键值状态改变 （键值对状态开关）*/
    async onKeyVal_typeChange(record, flag) {
      console.log('被输出值{ record.,flag }的输出结果是：', record, flag)
      record.status = flag === true ? '0' : '1'
      try {
        let res = await this.$api.modifyDictKeyVal(record)
        if (res.code === 0) {
          this.$message.success('修改成功')
          this.refresh()
        }
      } catch (err) {
        console.log(err, '字典-键值对开关错误')
      }
    },
    handleTableChange1({ current, pageSize }) {
      // console.log(current, pageSize)
      this.pagination1.current = current
      this.pagination1.pageSize = pageSize
      let params = {
        limit: pageSize,
        page: current,
      }
      if (this.searchValue) {
        this.$set(params, 'name', this.searchValue)
        this.getDictTypeLikelist(params)
      } else {
        this.getDictionaryList(params)
      }
    },
    handleTableChange2({ current, pageSize }) {
      // console.log(current, pageSize)
      this.pagination2.current = current
      this.pagination2.pageSize = pageSize
      let { dictType } = this.currentDict
      let params = {
        dictType,
        limit: pageSize,
        page: current,
      }
      this.getDictKeyValueList(params)
    },
  },
}
</script>

<style lang="less" scoped>
.dataDictionary {
  // padding: 15px;
  min-height: 100%;
  background: #f0f2f5;
  .box {
    border: 1px solid #f0f0f0;
    padding: 10px;
    .keyName {
      padding-bottom: 10px;
      border-bottom: 1px solid #f0f0f0;
    }
    .title_option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
}

.card_title {
  font-family: 'PingFangSC Medium';
  // font-weight: 500;
  font-size: 15px;
  text-align: left;
  color: #292929;
  font-weight: bold;
}

.add-btn {
  margin-bottom: 10px;
  padding: 0;
}

.ant-card {
  min-height: calc(100vh - 145px);
  position: relative;
}

.empty {
  position: absolute;
  top: 167px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'PingFangSC';
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 16px;
  text-align: center;
  & > div:nth-of-type(1) {
    img {
      width: 90px;
      height: 75px;
    }
    margin-bottom: 15px;
  }
  & > div:nth-of-type(2) {
    color: #292929;
    margin-bottom: 5px;
  }
  & > div:nth-of-type(1) {
    color: #bbbcbf;
  }
}

/deep/ .ant-table-align-left {
  height: 44px !important;
  padding: 0;
  padding-left: 16px;
  font-family: 'PingFang SC Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #333;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 10px 16px;
}

/deep/
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #f8f8f9 !important;
}

/deep/ .ant-table-tbody > .selectedClass {
  // background-color: #f8f8f9 !important;
  border: 1px solid #0059ff;
}

/deep/ .ant-table-fixed .ant-table-row-hover,
.ant-table-fixed .ant-table-row-hover > td {
  background: #f8f8f9 !important;
}

/deep/ .delete:hover a {
  background: #fef0f0;
  /* opacity: 0.1; */
  color: #f56c6c !important;
}
/deep/ .edit:hover a {
  background: #f9f9f9;
  /* opacity: 0.1; */
  color: #0059ff;
}
</style>

<style lang="less">
.selectedClass {
  border-radius: 8px;
  background: #f5f8ff;
  border-bottom: 1px solid #0059ff;
  color: #0059ff;
}

.ant-table-tbody > .selectedClass {
  // background-color: #f8f8f9 !important;
  border: 1px solid #0059ff;
}

.option_item {
  width: 71px;
  height: 32px;
  background: transparent;
  text-align: center;
  color: #2d2d2d;
}
</style>
