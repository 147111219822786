<template>
  <a-modal
    :visible="visible"
    @cancel="handleCancel"
    :title="title[type]"
    :width="560"
  >
    <a-form :form="form" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
      <a-form-item label="是否公开">
        <a-switch
          v-decorator="[
            'isPub',
            {
              valuePropName: 'checked',
              initialValue:
                type == 'add' ? false : dictInfo.isPub === '1' ? true : false,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="描述">
        <a-input
          :maxLength="45"
          placeholder="请输入"
          v-decorator="[
            'dictName',
            {
              rules: [
                { required: true, message: '请输入描述', whitespace: true },
              ],
              initialValue: type == 'add' ? '' : dictInfo.dictName,
            },
          ]"
        />
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('dictName') }}/45</span
        >
      </a-form-item>
      <a-form-item label="类型">
        <a-input
          placeholder="请输入"
          :disabled="type !== 'add'"
          v-decorator="[
            'dictType',
            {
              rules: [
                { required: true, message: '请输入类型', whitespace: true },
              ],
              initialValue: type == 'add' ? '' : dictInfo.dictType,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="状态">
        <a-switch
          v-decorator="[
            'status',
            {
              valuePropName: 'checked',
              initialValue:
                type == 'add' ? false : dictInfo.status === '0' ? true : false,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea
          placeholder="请输入"
          :maxLength="200"
          :rows="4"
          v-decorator="[
            'remark',
            { initialValue: type == 'add' ? '' : dictInfo.remark },
          ]"
        />
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('remark') }}/200</span
        >
      </a-form-item>
    </a-form>
    <template slot="footer">
      <div class="btn-group">
        <a-button class="close-btn" @click="handleCancel">取消</a-button>
        <a-button v-if="type == 'add'" type="primary" @click="submit">
          添加
        </a-button>
        <a-button v-if="type == 'edit'" type="primary" @click="submit">
          保存
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'DictModal',
  data() {
    return {
      title: {
        add: '新增字典',
        view: '查看字典',
        edit: '修改字典',
      },
      form: this.$form.createForm(this),
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'add',
    },
    dictInfo: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.$nextTick(() => {
      this.$forceUpdate()
    })
  },
  methods: {
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    submit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.status = values.status === true ? '0' : '1'
          values.isPub = values.isPub === true ? '1' : '0'
          if (this.type === 'add') {
            /* 添加字典 */
            values.tid = -1
            let res = await this.$api.addDict(values)
            if (res.code === 0) {
              this.$message.success('添加字典成功')
              this.$emit('refresh')
            }
          } else if (this.type == 'edit') {
            /* 修改字典 */
            values.dictId = this.dictInfo.dictId
            let res = await this.$api.modifyDict(values)
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.$emit('refresh')
            }
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 20px;
  .ant-form-item-lable {
    padding: 0 0 3px;
  }
}

/deep/ .ant-modal {
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 12px 24px;
  }
  .ant-modal-title {
    font-family: 'PingFangSC Medium';
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    color: #292929;
  }
}
</style>
