<template>
  <a-modal :visible="visible" @cancel="handleCancel" :title="title[type]">
    <a-form :form="form" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
      <a-form-item label="标签">
        <a-input
          placeholder="请输入"
          :maxLength="45"
          v-decorator="[
            'dictLabel',
            {
              rules: [
                { required: true, message: '请输入标签！', whitespace: true },
              ],
              initialValue: type == 'add' ? '' : keyValInfo.dictLabel,
            },
          ]"
        />
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('dictLabel') }}/45</span
        >
      </a-form-item>
      <a-form-item label="键值">
        <a-input
          placeholder="请输入"
          v-decorator="[
            'dictValue',
            {
              rules: [
                { required: true, message: '请输入键值', whitespace: true },
              ],
              initialValue: type == 'add' ? '' : keyValInfo.dictValue,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="排序">
        <a-input
          placeholder="请输入"
          v-decorator="[
            'dictSort',
            {
              rules: [{ required: true, message: '请输入排序！' }],
              initialValue: type == 'add' ? '' : keyValInfo.dictSort,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="是否默认">
        <a-radio-group
          v-decorator="[
            'isDefault',
            { initialValue: type == 'add' ? 'N' : keyValInfo.isDefault },
          ]"
        >
          <a-radio value="Y"> Yes </a-radio>
          <a-radio value="N"> No </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="状态">
        <a-switch
          v-decorator="[
            'status',
            {
              valuePropName: 'checked',
              initialValue:
                type == 'add'
                  ? false
                  : keyValInfo.status === '0'
                  ? true
                  : false,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea
          placeholder="请输入"
          :maxLength="200"
          :rows="4"
          v-decorator="[
            'remark',
            { initialValue: type == 'add' ? '' : keyValInfo.remark },
          ]"
        />
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('remark') }}/200</span
        >
      </a-form-item>
    </a-form>
    <template slot="footer">
      <div class="btn-group">
        <a-button class="close-btn" @click="handleCancel">关闭</a-button>
        <a-button v-if="type == 'add'" type="primary" @click="submit">
          添加
        </a-button>
        <a-button v-if="type == 'edit'" type="primary" @click="submit">
          保存
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'DictModal',
  data() {
    return {
      title: {
        add: '添加键值',
        edit: '编辑键值',
      },
      form: this.$form.createForm(this),
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'add',
    },
    keyValInfo: {
      type: Object,
      default: () => {},
    },
    currentDict: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.$nextTick(() => {
      this.$forceUpdate()
    })
  },
  methods: {
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    submit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.status = values.status === true ? '0' : '1'
          if (this.type === 'add') {
            /* 添加键值 */
            values.dictType = this.currentDict.dictType
            values.tid = -1
            let res = await this.$api.addDictKeyVal(values)
            if (res.code === 0) {
              this.$message.success('添加键值对成功')
              this.$emit('refresh')
            }
          } else if (this.type == 'edit') {
            /* 修改键值 */
            values.dictCode = this.keyValInfo.dictCode
            let res = await this.$api.modifyDictKeyVal(values)
            if (res.code === 0) {
              this.$message.success('修改成功')
              this.$emit('refresh')
            }
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 20px;
  .ant-form-item-lable {
    padding: 0 0 3px;
  }
}

/deep/ .ant-modal {
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 12px 24px;
  }
  .ant-modal-title {
    font-family: 'PingFangSC Medium';
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    color: #292929;
  }
}
</style>
